import type { GqlError } from "nuxt-graphql-client";

function isGqlError(error: unknown): error is GqlError {
  return (error as GqlError).client !== undefined;
}
export type GqlErrorHandlingOptions = {
  toast?: {
    title?: string;
    description?: string;
  };
  StatusMessage404?: string;
  StatusMessage403?: string;
  redirect?: boolean;
  dataResponseKey?: string; // if set data will be checked for a null value and if it is null 404 error will be shown
};

export default function useGqlErrorHandling(
  error: Ref<unknown>,
  data: Ref<unknown>,
  options?: GqlErrorHandlingOptions
) {
  const { $toast } = useNuxtApp();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch(data, (data: any) => {
    if (options?.dataResponseKey && data && data[options.dataResponseKey] === null) {
      return $toast.error(options?.toast?.title || `Access Denied`, {
        description: options?.toast?.description || `You are not authorized to modify this data`
      });
    }
    if (!data && options?.redirect) {
      showError(
        createError({
          statusCode: 404,
          statusMessage: options?.StatusMessage404 || "Page Not Found"
        })
      );
    }
  });
  watch(error, (error) => {
    console.error("error in GqlErrorHandling", JSON.stringify(error, null, 2));
    if (!error) return;
    if (isGqlError(error)) {
      console.error("Is GqlError", JSON.stringify(error, null, 2));
      if (options?.redirect) {
        showError(
          createError({
            statusCode: 500,
            statusMessage:
              options?.StatusMessage404 ||
              `Error on ${error.operationName}
            ${error.gqlErrors[0].message}`
          })
        );
      } else {
        $toast.error(options?.toast?.title || `Error on ${error.operationName}`, {
          description: options?.toast?.description || error.gqlErrors[0]?.message || "Unkown Error"
        });
      }
    } else {
      console.error("Is no GqlError", JSON.stringify(error, null, 2));
      throw error;
    }
  });
  return;
}
